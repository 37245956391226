import React from 'react'
import '../stylesheets/all.sass'

const NotFound = () => {
  return <section className="error-page">
      <img src="/img/home/error-icon.png" alt="error" />
      <div class="dialog">
          <h1>Welp, that's awkward</h1>
          <p>
            Something isn't adding up.
            (we're doing our best to build quickly,
            so a spilled martini is bound to happen)
            Please click the back or refresh button, and if that is no go,
            shoot us an email support@penny-finance.com
          </p>
          <p>Take me to <a href="/">Home</a></p>
      </div>
    </section>
}

export default NotFound
